.vue-map-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.bottom-sheet {
  top: calc(100vh - 260px);
}
/*# sourceMappingURL=src/layouts/mobile/derbygo/index.css.map */